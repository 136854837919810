<template>
  <div class="full-area" :class="{'feedback': feedback, 'has-image': data.fields.questionImage}">
    <FeedbackScreen
      :data="data"
      :feedback="feedback"
      v-on:retry="retry()"
      v-on:next="clearAndGo()"
    ></FeedbackScreen>

    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.questionSubtitle"
    >{{data.fields.questionSubtitle}}</h2>

    <content class="contentx" :class="`large-box-${data.fields.largeBox} align-top-${!!data.fields.questionImage } smaller-box-${randomizedOptions.length > 2}`">
      <div class="grid-wrapper delay-entry short-delay">
        <div
          :span="12"
          v-for="value in randomizedOptions"
          :key="value"
          class="binary-box"
          :class="{'selected-box': form.selected[0] === value}"
          @click="selectThis(value)"
        >
          <div class="center-me" v-html="getRichText(value)"></div>
        </div>
      </div>
    </content>
    <footer>
      <SkipQuestionButton
        :location="'locate-bottom'"
        v-on:skip="selectThis('S99')"
        :is-mandatory="data.fields.isMandatory"
      ></SkipQuestionButton>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Dilemma',

  props: [ 'data' ],

  components: {
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      feedback: '',
      locked: false,
      randomizedFormula: [],
      randomizedOptions: [],
      randomizedSkip: '',
      form: {
        type: 'dilemma',
        identifier: this.data.fields.title,
        numberOfOptions: this.data.fields.textOptions.length,
        options: this.data.fields.textOptions,
        question: this.data.fields.questionTitle,
        id: this.data.sys.id,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      }
    }
  },

  methods: {
    getRichText (text) {
      return text.replace(/{{br}}/g, '<br>')
    },
    randomize () {
      if (this.data.fields.noRandomize) {
        this.randomizedOptions = this.data.fields.textOptions
        this.randomizedFormula = this.data.fields.formula
        this.randomizedSkip = this.data.fields.skip
        return
      }
      // Randomize images and formulas so that they go together
      const options = this.data.fields.textOptions
      var temp = []
      for (var i = 0; i < this.data.fields.textOptions.length; i++) {
        temp.push(i)
      }
      temp.sort(function () { return 0.5 - Math.random() })
      this.randomizedOptions = []
      this.randomizedFormula = []
      var randomizedSkipArray = []

      if (this.data.fields.skip) {
        var explodedSkip = this.data.fields.skip.split(',')
      }

      temp.forEach(index => {
        this.randomizedOptions.push(this.data.fields.textOptions[index])
        if (this.data.fields.formula) {
          this.randomizedFormula.push(this.data.fields.formula[index])
        }
        if (this.data.fields.skip) {
          randomizedSkipArray.push(explodedSkip[index])
        }
      })

      if (this.data.fields.skip) {
        randomizedSkipArray.push(explodedSkip[explodedSkip.length - 1])
        this.randomizedSkip = randomizedSkipArray.join(',')
      }
    },
    retry () {
      this.form.selected = []
      this.locked = false
      this.feedback = ''
    },
    clearAndGo () {
      this.feedback = ''
      this.emitNext()
    },
    emitNext () {
      if (!this.feedback) {
        if (this.data.fields.storeResponseLocally) {
          this.$store.commit('STORE_LOCAL_RESPONSE', { key: this.form.identifier, value: this.form.selected[0] })
        }
        this.$emit('next', this.form)
      }
    },
    selectThis (item) {
      if (this.locked) {
        return
      }
      this.form.selected = [item]
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.calculateScores()
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value && value.length) {
        this.form.timestamps.valid = new Date()
        this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
        return true
      } else {
        return false
      }
    },
    calculateScores () {
      this.locked = true
      this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.randomizedFormula,
        selected: this.form.selected,
        options: this.randomizedOptions,
        maxAnswers: 1,
        skip: this.randomizedSkip
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.feedback = scores.feedback
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    }
  },

  mounted () {
    this.randomize()
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal)) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.large-box-true {
  .binary-box {
    padding-bottom: calc(100% * 3/4);
  }
}
.smaller-box-true {
  .binary-box {
    padding-bottom: 35%;
  }
}
.grid-wrapper {
  padding-top: -100px;
}

.center-me {
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.selected-box {
  animation: standOut 0.4s ease-in-out forwards;
  background-color: $color-emphasis-alt;
}
.contentx {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin-bottom: 60px;
  align-items: center;
}
.contentx {
  margin-top: 0;
  &.align-top-true {
    align-items: flex-start;
  }
  .binary-box {
    @include breakpointHeight($square) {
      line-height: 1.1;
      font-size: 17px !important;
    }
  }
}
.has-image {
  .contentx {
    margin-bottom: 0;
  }
}
</style>
